<template>
    <div class="wrapper">
        <div class="bar"></div>
        <div class="nav-item-wrapper">
            <div v-for="(item, index) in Table" :key="index" class="nav-item">
                    <div v-if="item.link !== 'no'" class="nav-item-text">
                        <a :href="item.link" class="clickable">
                            <b>{{ $t(item.text) }}</b>
                        </a>
                    </div>
                    <div v-else class="nav-item-text">
                        <b>{{ $t(item.text) }}</b>
                    </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { PropType } from "vue"
export default {
    name: "SubsectionBar",
    props: {
        Table: {
            type: [] as PropType<({ text: string; link: string; } | { text: string; link?: undefined; })[]>,
            default: [""],
        }
    }
}
</script>
<style scoped>

.header span {
  border-bottom-color: #9a6b02;
  padding-bottom: 1px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  width: 20vh;
  z-index: 100;
  font-size: 3vh;
}
  /*9a6b02   1a1c13 959595 2d2e23
  /* Add any other styles for the top-bar here */


a:hover {
  color: #c4c4c4;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  color: #c4c4c4;
}

.header a {
  border-bottom-color: #9a6b02;
  padding-bottom: 1px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  width: 20vh;
  z-index: 100;
  font-size: 3vh;
  cursor: pointer;
}
body {
  margin: 0;
  padding: 0;
}
a {
  color: #959595;
  text-decoration: none;
}

</style>
<style scoped>

.hexagon {
    height: 25vh;
}

@media only screen and (max-width:1024px) {
    .hexagon {
        height: 0vh;
    }
}

/* Add your component styles here */
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bar {
    position: absolute;
    background-color: #2d2e23;
    width: 100%;
    height: 5vh;

}

.nav-item-wrapper {
    width: 80%;
    margin: auto;
    position: absolute;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.nav-item {
    background-image: url('../assets/hexagon.svg');
    background-size: cover;
    height: 145px;
    width: 22rem;
}
@media screen and (min-width: 3000px){
    .nav-item {
    height: 15vh;
    width: 1.7rem;
    }

}

.nav-item-text {
    position: relative;
    font-size: 3vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.nav-item-text b {
    border-bottom-color: #9a6b02;
    padding-bottom: 1px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}


@media only screen and (max-width:1200px) {
    .nav-item-wrapper {
        width: 100%;
        padding-left: 0%;
        left: 0%;
        position: relative;
        height: 5vh;
        padding: 0px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .nav-item {
        background-image: none;
    }

}
</style>
