<template>
    <div class="selection-bar">
        <div class="language-select">
            <img src="..\assets\germany.svg" alt="German" :class="{ 'grayed-out': $i18n.locale !== 'de' }" @click="selectLanguage('de')" />
            <span>/</span>
            <img src="..\assets\union-jack.svg" alt="English" :class="{ 'grayed-out': $i18n.locale !== 'en' }" @click="selectLanguage('en')" />
        </div>
        <div class="member-text header clickable">
            <a href="https://member.501st.org" target="_blank"><b>{{ $t("member") }} </b></a>
        </div>
    </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';
import useI18n from '../composables/i18n';

export default defineComponent({
    setup() {
        const i18n = useI18n();
        return { i18n };
    },
    data() {
    },
    methods: {
        selectLanguage(language: any) {
            this.i18n.global.locale = language;
        }
    }
});

</script>
<style scoped>

.header span {
  border-bottom-color: #9a6b02;
  padding-bottom: 1px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  width: 20vh;
  z-index: 100;
  font-size: 3vh;
}
  /*9a6b02   1a1c13 959595 2d2e23
  /* Add any other styles for the top-bar here */


a:hover {
  color: #c4c4c4;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  color: #c4c4c4;
}

.header a {
  border-bottom-color: #9a6b02;
  padding-bottom: 1px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  width: 20vh;
  z-index: 100;
  font-size: 3vh;
  cursor: pointer;
}
body {
  margin: 0;
  padding: 0;
}
a {
  color: #959595;
  text-decoration: none;
}

</style>
<style scoped>
.header span {
  border-bottom-color: #9a6b02;
  padding-bottom: 1px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  width: 20vh;
  z-index: 100;
  font-size: 3vh;
}
.header a {
  border-bottom-color: #9a6b02;
  padding-bottom: 1px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  width: 20vh;
  z-index: 100;
  font-size: 3vh;
  cursor: pointer;
}
.selection-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 5vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #2d2e23;
    padding: 1px;
    color: #959595;
    z-index: 100;
}


.language-select {
    display: flex;
    width: 10vw;
    font-size: 5vh;
}

.language-select img {
    height: 3.5vh;
    padding: 1vh;
    cursor: pointer;
    margin-top:0.1vh;
}


.grayed-out {
    filter: grayscale(100%);
}


.member-text {
    margin-right: 15vw;
    margin-left: 10vw;
    position: relative;
    height: 4vh;
    font-size: 3.2vh;
}
@media screen and (max-width: 1024px){
    .selection-bar {
        width: 50vw;
        padding-left: 45vw;
        padding-right: 5vw;
        justify-content: space-between;
    }

    .member-text {
        margin: 0;
        height: auto;
        width: auto;
    }
}


</style>