import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "box" }
const _hoisted_2 = { class: "footer" }
const _hoisted_3 = { class: "left-links" }
const _hoisted_4 = { href: "/datenschutz" }
const _hoisted_5 = { class: "footer-disclaimer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", _hoisted_4, _toDisplayString(_ctx.$t("privacy")), 1)
      ]),
      _createElementVNode("div", null, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "footer-logo" }, [
          _createElementVNode("img", {
            src: "https://support.robertsspaceindustries.com/hc/article_attachments/360022704853/MadeByTheCommunity_White.png",
            alt: "Made by the Community"
          })
        ], -1)),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("disclaimer")), 1)
        ])
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "right-links" }, null, -1))
    ])
  ]))
}