import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "mainappcomponent",
  class: "",
  style: {"zoom":"1"}
}
const _hoisted_2 = { id: "bodyWrapper" }
const _hoisted_3 = { class: "page-wrapper" }
const _hoisted_4 = {
  id: "contentbody",
  class: "",
  style: {}
}
const _hoisted_5 = { class: "trailer-section" }
const _hoisted_6 = {
  class: "l-section",
  id: "game"
}
const _hoisted_7 = { class: "l-block l-block--centered c-articles" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Trailer = _resolveComponent("Trailer")!
  const _component_SubSection = _resolveComponent("SubSection")!
  const _component_InfoComponent = _resolveComponent("InfoComponent")!
  const _component_PlatformPart = _resolveComponent("PlatformPart")!

  return (_openBlock(), _createElementBlock("body", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "c-pattern c-pattern--left" }, null, -1)),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "c-pattern c-pattern--right" }, null, -1)),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Trailer)
          ]),
          _createVNode(_component_SubSection, {
            class: "subsection-1",
            Table: $data.SubSection1
          }, null, 8, ["Table"]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.InfoTable, (item, index) => {
                return (_openBlock(), _createBlock(_component_InfoComponent, {
                  imageSrc: item.imageSrc,
                  text: item.text,
                  key: index
                }, null, 8, ["imageSrc", "text"]))
              }), 128))
            ])
          ]),
          _createVNode(_component_SubSection, { Table: $data.SubSection2 }, null, 8, ["Table"]),
          _createVNode(_component_PlatformPart, { id: "contact" })
        ])
      ])
    ])
  ]))
}