<template>
    <div class="image-container">

        <!-- Image 1 -->
        <div class="image">
            <a href="https://discord.501st.org" target="_blank">
                <img src="../assets/discord.svg" alt="Discord" />
            </a>
            <!-- Image content here -->
        </div>

        <div class="image">
            <!-- Image content here -->
            <a href="https://www.instagram.com/501st_uee/" target="_blank">
                <img src="../assets/insta.svg" alt="Instagram" />
            </a>

        </div>

        <!-- Image 2 -->
        <div class="image">
            <!-- Image content here -->
            <a href="ts3server://ts.501st.org" target="_blank">
                <img src="../assets/teamspeak.svg" alt="TS3" />
            </a>

        </div>
    </div>
</template>

<style scoped>
.image-container {
    /* Your styles for the image container */
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 70%;
    left: 15%;
    position: relative;
}

.image img {
    width: 10rem;
    color: #9a6b02;
}
</style>