
<template>
  <div id="app">
    <SelectionBar />
    <LandingPage />
    <Footer />
  </div>

</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import SelectionBar from './components/SelectionBar.vue';
import LandingPage from './components/LandingPageComponent.vue';
import Footer from './components/FooterComponent.vue';

console.log()

@Options({
  components: {
    SelectionBar,
    Footer,
    LandingPage,
  }
})


export default class App extends Vue {}

</script>

<style>
#application, #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #959595;
  position:initial;
  background-color: #1a1c13;
  background-image: linear-gradient(to right,#41300c .01px,transparent 1px),linear-gradient(to bottom,#41300c .01px,transparent 1px);  background-size: 100px 100px;
  background-position: center center;
}
  /*9a6b02   1a1c13 959595 2d2e23
  /* Add any other styles for the top-bar here */


a:hover {
  color: #c4c4c4;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  color: #c4c4c4;
}
body {
  margin: 0;
  padding: 0;
  background-color: #2d2e23;
}
a {
  color: #959595;
  text-decoration: none;
}

</style>
