import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/germany.svg'
import _imports_1 from '../assets/union-jack.svg'


const _hoisted_1 = { class: "selection-bar" }
const _hoisted_2 = { class: "language-select" }
const _hoisted_3 = { class: "member-text header clickable" }
const _hoisted_4 = {
  href: "https://member.501st.org",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "German",
        class: _normalizeClass({ 'grayed-out': _ctx.$i18n.locale !== 'de' }),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectLanguage('de')))
      }, null, 2),
      _cache[2] || (_cache[2] = _createElementVNode("span", null, "/", -1)),
      _createElementVNode("img", {
        src: _imports_1,
        alt: "English",
        class: _normalizeClass({ 'grayed-out': _ctx.$i18n.locale !== 'en' }),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectLanguage('en')))
      }, null, 2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("a", _hoisted_4, [
        _createElementVNode("b", null, _toDisplayString(_ctx.$t("member")), 1)
      ])
    ])
  ]))
}