import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "nav-item-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "nav-item-text"
}
const _hoisted_4 = ["href"]
const _hoisted_5 = {
  key: 1,
  class: "nav-item-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "bar" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.Table, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "nav-item"
        }, [
          (item.link !== 'no')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("a", {
                  href: item.link,
                  class: "clickable"
                }, [
                  _createElementVNode("b", null, _toDisplayString(_ctx.$t(item.text)), 1)
                ], 8, _hoisted_4)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("b", null, _toDisplayString(_ctx.$t(item.text)), 1)
              ]))
        ]))
      }), 128))
    ])
  ]))
}