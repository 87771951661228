<template>
  <body id="mainappcomponent" class="" style="zoom: 1;">
    <div id="bodyWrapper">
      <div class="page-wrapper">
        <div id="contentbody" class="" style="">

          <div class="c-pattern c-pattern--left">
            <!-- Left Diagonal Pattern -->
          </div>
          <div class="c-pattern c-pattern--right">
            <!-- Left Diagonal Pattern -->
          </div>

          <div class="trailer-section">
            <Trailer />
          </div>
          <SubSection class="subsection-1" :Table="SubSection1" />


          <div class="l-section" id="game">
            <!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->
            <div class="l-block l-block--centered c-articles">
              <!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->
              <!--Info boxes later-->
              <InfoComponent v-for="(item, index) in InfoTable" :imageSrc="item.imageSrc" :text="item.text"
                :key="index" />
              <!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->
            </div>
          </div>


          <SubSection :Table="SubSection2"  />

          <PlatformPart id="contact"/>
        </div>
      </div>
    </div>
  </body>
</template>

<script lang="ts">
import InfoComponent from './InfoComponent.vue';
import Trailer from "./TrailerComponent.vue"
import SubSection from "./SubsectionBarComponent.vue"
import PlatformPart from "./PlatformComponent.vue"
export default {
  name: 'LandingPageComponent',
  data() {
    return {
      InfoTable, // Initialize InfoTable as an empty array
      SubSection1,
      SubSection2
    };
  },

  components: {
    Trailer,
    InfoComponent,
    SubSection,
    PlatformPart,
  },
};
import data from '../locales/InfoTable.json';
const InfoTable = data.InfoTable;
const SubSection1 = [
  {
    "text": "RSI",
    "link": "https://rsi.501st.org"
  },
  {
    "text": "info",
    "link": "#game"
  },
  {
    "text": "contactsmall",
    "link": "#contact"
  }
]
const SubSection2 = [
  {
    "text": "contactsmall",
    "link": "no"
  },


]
</script>




<style scoped>
.page-wrapper {
  position: relative;
  z-index: 10
}

#mainappcomponent {
  background: #1a1d17;
  box-sizing: border-box;
  min-width: 0
}

#mainappcomponent * {
  font-family: "univia-pro", sans-serif
}

#mainappcomponent *,
#mainappcomponent *:before,
#mainappcomponent *:after {
  box-sizing: inherit
}

#mainappcomponent #contentbody {
  background-image: linear-gradient(to right, #41300c .01px, transparent 1px), linear-gradient(to bottom, #41300c .01px, transparent 1px);
  background-size: 100px 100px;
  background-color: #1a1c13;
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
  background-position: center center;
}

#mainappcomponent .c-pattern {
  background: url('../assets/diagonal-pattern.svg') repeat 0% 0%;
  background-size: 36px 30px;
  height: 100%;
  position: absolute;
  top: 0;
  width: 36px;
  z-index: 5
}

#mainappcomponent .c-pattern--left {
  left: 15px
}

#mainappcomponent .c-pattern--right {
  right: 15px
}

#mainappcomponent .c-pattern--right {
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV";
}


@media (max-width:1024px) {
  #mainappcomponent .c-pattern--left {
    display: none
  }

  #mainappcomponent .c-pattern--right {
    display: none
  }

}

@media (min-width:1260px) {
  #mainappcomponent .c-pattern {
    background-size: 71px 60px;
    width: 71px
  }
}

@media (min-width:1400px) {
  #mainappcomponent .c-pattern--left {
    left: 7%
      /*pos balken*/
  }

  #mainappcomponent .c-pattern--right {
    right: 7%
  }
}
</style>

<style scoped>
@media screen and (min-width: 1024px) {

#mainappcomponent .l-section {
  width: 100%;
  padding-top: 110px;
  padding-bottom: 50px;
  position: relative
}


@media (max-width:950px) {
  #mainappcomponent .l-section {
    padding-top: 80px;
    padding-bottom: 0
  }
}


#mainappcomponent .l-block {
  position: relative;
  z-index: 4
}

#mainappcomponent .l-block--centered {
  margin: 0 auto;
  max-width: 1370px
}

@media (min-width:1400px) {
  #mainappcomponent .l-block {
    padding: 0
  }
}



#mainappcomponent .page-wrapper .c-article,
#mainappcomponent .c-brand-menu .c-article {
  margin-bottom: 146px;
  margin-left: 106px;
  position: relative
}

@media only screen and (max-width:1024px) {

  #mainappcomponent .page-wrapper .c-article,
  #mainappcomponent .c-brand-menu .c-article {
    margin-left: 54px
  }
}







/* InfoComponent2.vue orange box */

#mainappcomponent .page-wrapper .c-article:before,
#mainappcomponent .c-brand-menu .c-article:before {
  border: 1px solid transparent;
  border-image-source: url('../assets/article-border.svg');
  border-image-slice: 30 30 fill;
  border-image-width: 32px;
  content: '';
  height: calc(100% - 70px);
  left: calc(-9%);
  position: absolute;
  top: 35px;
  transition: all .75s cubic-bezier(.165, .84, .44, 1);
  -moz-transition: all .75s cubic-bezier(.165, .84, .44, 1);
  -webkit-transition: all .75s cubic-bezier(.165, .84, .44, 1);
  width: 100%;
  z-index: -1
}

@media only screen and (max-width:1024px) {

  #mainappcomponent .page-wrapper .c-article:before,
  #mainappcomponent .c-brand-menu .c-article:before {
    height: calc(100% - 35px);
    top: 17px
  }
}

@media (max-width:950px) {

  #mainappcomponent .page-wrapper .c-article:before,
  #mainappcomponent .c-brand-menu .c-article:before {
    display: none;
  }
}

@media (min-width:1400px) {

  #mainappcomponent .page-wrapper .c-article:before,
  #mainappcomponent .c-brand-menu .c-article:before {
    width: calc(100% + 17px)
  }
}

}
</style>