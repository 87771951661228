<template>
    <a href="https://cdn.501st.org/video/OrgTrailer.mp4" target="_blank">
    <video class="video-background" autoplay loop muted>
        <source src="https://cdn.501st.org/video/OrgTrailer.mp4" type="video/mp4">
    </video>
    </a>

    <!-- Logo centered in front -->
    <div class="logo">
        <img src="../assets/501stLogo.png" alt="">
    </div>
</template>

<style scoped>
.video-background {
  /* Your styles for the video background */
  max-width: 100vw;
  height: 95vh;
  filter: brightness(50%);
}

.logo img {
  /* Your styles for the logo */
  width: 20%;
  position: absolute;
  top: 23vh;
  left: 40%;
  opacity: 0.9;
}
@media only screen and (max-width:1024px) {
  .logo img {
    width: 30%;
    top: 14vh;
    left: 35%;
  }
  .video-background {
  /* Your styles for the video background */
  height: auto;
}

}
@media only screen and (max-width:800px) {
  .logo img {
    top: 8vh;
  }

}
</style>