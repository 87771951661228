import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "c-article",
  "data-wscitem": "s42_landing_game_prime"
}
const _hoisted_2 = { class: "c-article__video c-objectfit c-objectfit--cover" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "c-article__intro" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: $props.imageSrc,
        alt: "",
        "data-mediaslot": "one"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.text, (item, index) => {
        return (_openBlock(), _createElementBlock("p", { key: index }, [
          _createTextVNode(_toDisplayString(_ctx.$t(item)), 1),
          _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1))
        ]))
      }), 128))
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "c-article__full" }, null, -1))
  ]))
}