<template>
    <div class="c-article" data-wscitem="s42_landing_game_prime">
        <div class="c-article__video c-objectfit c-objectfit--cover">
            <img :src="imageSrc" alt="" data-mediaslot="one">

        </div>
        <div class="c-article__intro">
            <p v-for="(item, index) in text" :key="index"> {{ $t(item) }}<br></p>

        </div>
        <div class="c-article__full">

        </div>
    </div>
</template>

<script lang="ts">
import { PropType } from "vue"
export default {
	props: {
		imageSrc: {
			type: String,
			default: '../assets/germany.svg',
		},
		text: {
			type:  Array as PropType<string[]>,
			default: () => [""],
		},

	},
};

</script>

<style scoped>
.c-article__video img {
    background: #000b18;
    overflow: hidden;
    position: relative;
    z-index: 2;
    width: 100vw;
    max-height: 50vh;
    scale: auto;
}
.c-article__intro p {
    font-size: 15px;
    text-align: left;
    padding-left: 5vw;
    padding-right: 5vw;
}

</style>


<style scoped>
@media screen and (min-width: 1024px) {



p {
    text-align: left;
}


#mainappcomponent .c-objectfit img {
    display: block;
    height: 100%;
    width: 100%
}

#mainappcomponent .c-objectfit--cover img {
    object-fit: cover
}

#mainappcomponent *,
#mainappcomponent *:before,
#mainappcomponent *:after {
    box-sizing: inherit
}



.c-article__intro {
    position: absolute !important;
    left: -8%;
    text-align: left;
}

.c-article:nth-child(even) .c-article__intro {
    position: absolute;
    right: -8%;
    left: auto;
}




#mainappcomponent .page-wrapper .c-article__video,
#mainappcomponent .c-brand-menu .c-article__video {
    background: #000b18;
    height: 430px;
    overflow: hidden;
    position: relative;
    z-index: 2
}


@media (max-width:950px) {

    #mainappcomponent .page-wrapper .c-article__video,
    #mainappcomponent .c-brand-menu .c-article__video {
        width: 100%
    }
}

@media only screen and (max-width:1024px) {

    #mainappcomponent .page-wrapper .c-article__video,
    #mainappcomponent .c-brand-menu .c-article__video {
        height: 339px
    }
}

@media (min-width :950px) {

    #mainappcomponent .page-wrapper .c-article__video,
    #mainappcomponent .c-brand-menu .c-article__video {
        float: right; /*initial img pos*/
        width: 60%
    }


}

@media (min-width:1260px) {

    #mainappcomponent .page-wrapper .c-article__video,
    #mainappcomponent .c-brand-menu .c-article__video {
        width: 50%
    }
}

@media (min-width:1400px) {

    #mainappcomponent .page-wrapper .c-article__video,
    #mainappcomponent .c-brand-menu .c-article__video {
        width: 720px
    }
}

/*Text position */

@media only screen and (max-width:1024px) {

    #mainappcomponent .page-wrapper .c-article__intro p,
    #mainappcomponent .c-brand-menu .c-article__intro p {
        font-size: 13px;
        line-height: 19px;
    }
}

@media (max-width:950px) {

    #mainappcomponent .page-wrapper .c-article__intro,
    #mainappcomponent .c-brand-menu .c-article__intro {
        padding: 22px 70px 0 35px
    }
}

@media (min-width :950px) {

    #mainappcomponent .page-wrapper .c-article__intro,
    #mainappcomponent .c-brand-menu .c-article__intro {
        float: left;
        height: 339px;
        padding: 50px 35px 35px; /*pos strange only*/
        position: relative;
        width: 40%
    }
}

@media (min-width:1024px) {

    #mainappcomponent .page-wrapper .c-article__intro,
    #mainappcomponent .c-brand-menu .c-article__intro {
        height: 430px;
        padding: 68px 35px 35px
    }
}

@media (min-width:1260px) {

    #mainappcomponent .page-wrapper .c-article__intro,
    #mainappcomponent .c-brand-menu .c-article__intro {
        width: 50%
    }

    #mainappcomponent .page-wrapper .c-article__intro p,
    #mainappcomponent .c-brand-menu .c-article__intro p {
        font-size: 15px;
        line-height: 26px
    }
}

@media (min-width:1400px) {

    #mainappcomponent .page-wrapper .c-article__intro,
    #mainappcomponent .c-brand-menu .c-article__intro {
        width: calc(100% - 620px) /*breite text*/
    }
}





/*linien und hähe*/

#mainappcomponent .page-wrapper .c-article__full,
#mainappcomponent .c-brand-menu .c-article__full {
    clear: both;
    height: 0;
    overflow: hidden;
    transition: height .75s cubic-bezier(.165, .84, .44, 1);
    -moz-transition: height .75s cubic-bezier(.165, .84, .44, 1);
    -webkit-transition: height .75s cubic-bezier(.165, .84, .44, 1)
}



@media (min-width :1024px) {

    #mainappcomponent .page-wrapper .c-article:nth-child(even),
    #mainappcomponent .c-brand-menu .c-article:nth-child(even) {
        margin-left: 0;
        margin-right: 106px
    }

    #mainappcomponent .page-wrapper .c-article:nth-child(even):before,
    #mainappcomponent .c-brand-menu .c-article:nth-child(even):before {
        left: 0%;
        transform: translate(9%, 0) scaleX(-1);
    }


    #mainappcomponent .page-wrapper .c-article:nth-child(even) .c-article__video,
    #mainappcomponent .c-brand-menu .c-article:nth-child(even) .c-article__video {
        float: left
    }


}

@media only screen and (max-width:1024px) {

    #mainappcomponent .page-wrapper .c-article:nth-child(even),
    #mainappcomponent .c-brand-menu .c-article:nth-child(even) {
        margin-right: 54px
    }

    #mainappcomponent .page-wrapper .c-article:nth-child(even) .c-article__title,
    #mainappcomponent .c-brand-menu .c-article:nth-child(even) .c-article__title {
        left: 82px
    }
}


}
</style>

