import { createI18n } from 'vue-i18n'
import en from '../locales/en.json'
import de from '../locales/de.json'

const i18n = createI18n({
    locale: navigator.language.startsWith('de') ? 'de' : 'en',
    messages: {
      en,
      de
    },
    fallbackLocale: 'en'
  });

export default function useI18n() {
    return i18n;
}